.account-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    max-width: 400px;
    margin: auto;
  }
  
  .account-profile__avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .account-profile__info {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .account-profile__name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .account-profile__email,
  .account-profile__phone,
  .account-profile__address {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .account-profile__orders {
    width: 100%;
  }
  
  .account-profile__orders h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .account-profile__orders ul {
    list-style: none;
    padding: 0;
  }
  
  .account-profile__orders li {
    background-color: #e6e6e6;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  