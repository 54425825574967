/* AddContract.css */
.add-contract-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contract-details,
  .bank-details,
  .contract-actions {
    margin-bottom: 20px;
  }
  
  input[type="text"],
  input[type="date"] {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  input[type="text"]:last-child {
    margin-right: 0;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #5cb85c;
    color: white;
    cursor: pointer;
    margin-right: 10px;
  }
  
  button:hover {
    background-color: #4cae4c;
  }
  
  button:second-child {
    background-color: #d9534f;
  }
  
  /* Добавьте необходимые стили для таблицы товаров и контактов */
  