/* Chat.css */
.chat-container {
    display: flex;
    border: 1px solid #ddd;
  }
  
  .users-list {
    flex: 1;
    border-right: 1px solid #ddd;
  }
  
  .user {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
  }
  
  .user.active {
    background-color: #e9e9e9;
  }
  
  .chat-messages {
    flex: 3;
    padding: 10px;
    overflow-y: auto;
  }
  
  .chat-message {
    background-color: #f1f1f1;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .chat-input {
    display: flex;
    padding: 10px;
  }
  
  .chat-input input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  .chat-input button {
    padding: 10px;
    border: none;
    background-color: #5cb85c;
    color: white;
    cursor: pointer;
  }
  
  .chat-input button:hover {
    background-color: #4cae4c;
  }
  