/* Invoice.css */
.invoice-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.invoice-info, .document-info {
  margin-bottom: 10px;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-table th, .invoice-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.invoice-summary {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.invoice-signatures p {
  display: inline-block;
  margin-right: 40px;
}
