.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff; /* Выберите подходящий цвет фона */
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Тень, если нужно */
  }
  
  .header__logo {
    font-weight: bold;
    font-size: 24px;
    color: #000; /* Выберите подходящий цвет текста */
  }
  
  .header__title {
    font-size: 20px;
    color: #555; /* Выберите подходящий цвет текста */
  }
  
  .header__user-info {
    display: flex;
    align-items: center;
  }
  
  .user-info__name {
    font-size: 16px;
    color: #333; /* Выберите подходящий цвет текста */
    margin-right: 10px;
  }
  
  .user-info__logout {
    background: none;
    border: none;
    cursor: pointer;
  }
  