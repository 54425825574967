.order-page-container {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .order-header h1 {
    font-size: 24px;
    color: #333;
  }
  
  .order-status span {
    background: #f2f2f2;
    padding: 5px 10px;
    border-radius: 4px;
    color: #333;
  }
  
  .order-details p,
  .delivery-info p,
  .order-total p {
    margin: 5px 0;
  }
  
  .delivery-info {
    display: flex;
    justify-content: space-between;
  }
  
  .order-items {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .order-items th,
  .order-items td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .order-items th {
    background-color: #f9f9f9;
  }
  
  .order-total {
    text-align: right;
    margin-top: 20px;
  }
  
  .order-comment {
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
  }
  
  .order-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .order-actions button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #5cb85c;
    color: white;
  }
  
  .order-actions button:first-child {
    background-color: #d9534f;
  }
  
  .order-actions button:hover {
    opacity: 0.8;
  }
  .create-invoice-btn {
    margin-left: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #4a90e2;
    color: white;
    font-size: 14px;
  }
  
  .create-invoice-btn:hover {
    opacity: 0.9;
  }