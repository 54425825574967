.add-user-form {
    width: 100%;
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .add-user-form input[type="text"],
  .add-user-form input[type="password"] {
    padding: 8px;
    margin: 4px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .add-user-form input[type="checkbox"] {
    margin-right: 8px;
  }
  
  .add-user-form button {
    padding: 8px 16px;
    margin-top: 8px;
    border: none;
    border-radius: 4px;
    background-color: #5cb85c;
    color: white;
    cursor: pointer;
  }
  
  .add-user-form button:hover {
    background-color: #4cae4c;
  }
  
  .add-user-form fieldset {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .add-user-form fieldset legend {
    padding: 0 4px;
  }
  
  .add-user-form label {
    display: block;
    margin-bottom: 4px;
  }
  