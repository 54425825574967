.users-container {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
  }
  
  .search-bar, .buttons {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }
  
  .search-bar input, .buttons button {
    padding: 8px;
    margin-right: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .search-bar input:last-child, .buttons button:last-child {
    margin-right: 0;
  }
  
  .users-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 16px;
  }
  
  .users-table th, .users-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .users-table th {
    background-color: #f2f2f2;
  }
  
  .selected {
    background-color: #e7e7e7;
  }
  