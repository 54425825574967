.document-types-container {
    width: 100%;
    max-width: 250px;
    margin: auto;
    padding: 16px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .actions button {
    padding: 8px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
  }
  
  .actions button:hover {
    background-color: #e7e7e7;
  }
  
  .actions button:disabled {
    background-color: #f3f3f3;
    color: #ccc;
    cursor: not-allowed;
  }
  
  .types-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .types-list li {
    padding: 8px;
    border: 1px solid #ddd;
    margin-bottom: 8px;
    cursor: pointer;
  }
  
  .types-list li.selected {
    background-color: #e7e7e7;
  }
  