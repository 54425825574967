.orders-container {
  
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Добавим тень для контейнера */
  border-radius: 8px; /* Скругление углов контейнера */
}


.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Легкая тень для таблицы */
}

.orders-table th, 
.orders-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.orders-table th {
  background-color: #e7e7e7; /* Изменим цвет для соответствия изображению */
  font-weight: bold; /* Делаем текст заголовков жирным */
}

/* Изменим стили для статусов в соответствии с изображением */
.status-in-progress {
  background-color: #f0ad4e; /* Оранжевый */
}

.status-delivering {
  background-color: #5bc0de; /* Голубой */
}

.status-en-route {
  background-color: #5cb85c; /* Зеленый */
}

.status-received {
  background-color: #428bca; /* Синий */
}
.filters {
  display: flex; /* Используем Flexbox для упорядочивания кнопок */
  justify-content: start; /* Выравниваем кнопки по началу контейнера */
  gap: 8px; /* Добавляем промежуток между кнопками */
  padding: 8px; /* Добавляем немного отступа вокруг кнопок */
  flex-wrap: wrap; /* Позволяет кнопкам переноситься на следующую строку, если не хватает места */
}
.filter-button {
  max-width: 150px;
  padding: 8px 16px;
  margin-right: 8px;
  border: none;
  border-radius: 4px;
  background-color: #e7e7e7; /* Светло-серый фон */
  cursor: pointer;
  font-weight: bold; /* Делаем текст на кнопках жирным */
  outline: none; /* Убираем контур фокуса */
}

/* Изменим стили для активного состояния фильтров */
.filter-button.active {
  background-color: #d7d7d7; /* Темно-серый фон для активного фильтра */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); /* Внутренняя тень для акцента */
}

.filter-button:focus,
.filter-button:active {
  background-color: #d7d7d7;
}

/* Стили для разделов по статусу заказа, как показано на изображении */
.status-filter {
  display: flex;
  gap: 4px; /* уменьшенное пространство между кнопками */
  margin-bottom: 8px; /* уменьшенный отступ снизу */
}

.status-filter span {
  font-size: 14px;
  font-weight: bold; /* Жирный шрифт для текста статуса */
  color: #333; /* Черный цвет текста */
}

.status-filter .status-indicator {
  width: 16px;
  height: 16px;
  border-radius: 50%; /* Круглая индикация статуса */
  display: inline-block;
  margin-right: 4px; /* Отступ справа от индикатора */
}

/* Дополнительные цвета для индикаторов статуса */
.status-indicator-in-progress {
  background-color: #f0ad4e;
}

.status-indicator-delivering {
  background-color: #5bc0de;
}

.status-indicator-en-route {
  background-color: #5cb85c;
}

.status-indicator-received {
  background-color: #428bca;
}
