/* Vehicles.css */
.vehicles-container {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .vehicles-header {
    display: flex;
    justify-content: start;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .vehicles-button {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background-color: #f0f0f0;
  }
  
  .vehicles-button:hover {
    background-color: #e2e2e2;
  }
  
  .vehicles-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin-bottom: 20px;
  }
  
  .vehicles-table th,
  .vehicles-table td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .vehicles-table th {
    background-color: #f9f9f9;
  }
  
  .vehicles-table td img {
    width: 24px; /* Пример размера для иконки сертификата */
  }
  