/* ClientsList.css */
.clients-list-container {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .clients-list-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .search-bar {
    display: flex;
  }
  
  .search-bar input {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-client-btn {
    padding: 10px 20px;
    border: none;
    background-color: #4a90e2;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-client-btn:hover {
    background-color: #357abd;
  }
  
  .clients-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .clients-table th,
  .clients-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .clients-table th {
    background-color: #f9f9f9;
  }
  