.orders-container {
    font-family: Arial, sans-serif;
  }
  
  .orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    text-align: left;
  }
  
  .orders-table th, .orders-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .orders-table th {
    background-color: #f2f2f2;
  }
  
  .status-in-progress {
    background-color: #f2e5a2; /* Цвет для статуса "в работе" */
  }
  
  .status-delivering {
    background-color: #f0f2a6; /* Цвет для статуса "доставляется" */
  }
  
  .status-en-route {
    background-color: #a2f2b5; /* Цвет для статуса "в пути" */
  }
  
  .status-received {
    background-color: #a2d2f2; /* Цвет для статуса "получен" */
  }
  
  .add-order-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50; /* Зеленый цвет кнопки */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-order-button:hover {
    background-color: #45a049;
  }
  
  /* Дополнительные стили для оплаты и иконок */
  .orders-table td {
    text-align: center;
  }
  
  .orders-table .payment-true {
    color: green;
  }
  
  .orders-table .payment-false {
    color: red;
  }
  