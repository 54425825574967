/* Стили компонента Водители */
.drivers-component {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    max-width: 400px; /* Или ширина в зависимости от вашего дизайна */
    margin: auto;
  }
  
  .drivers-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .drivers-header h2 {
    margin: 0;
    font-size: 24px; /* Размер шрифта в зависимости от вашего дизайна */
  }
  
  .button-panel {
    display: flex;
    gap: 10px; /* Расстояние между кнопками */
  }
  
  .button-panel button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #4a90e2; /* Цвет фона кнопок */
    color: white;
    font-size: 14px;
  }
  
  .button-panel button:hover {
    background-color: #357abd; /* Цвет фона кнопок при наведении */
  }
  
  .drivers-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  .drivers-table th,
  .drivers-table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }
  
  .drivers-table th {
    font-weight: bold;
  }
  
  .drivers-table td {
    vertical-align: middle;
  }
  
  .driver-documents-icon {
    width: 24px; /* Размер иконки */
    height: 24px; /* Размер иконки */
    cursor: pointer;
  }
  
  /* Дополнительные стили, если нужно */
  