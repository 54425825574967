.delivery-areas-container {
    font-family: Arial, sans-serif;
  }
  
  .buttons-container {
    margin-bottom: 10px;
  }
  
  button {
    margin-right: 5px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
  }
  
  button:hover {
    background-color: #e7e7e7;
  }
  
  button:disabled {
    cursor: default;
    opacity: 0.5;
  }
  
  .delivery-areas-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .delivery-areas-table th,
  .delivery-areas-table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .delivery-areas-table th {
    background-color: #f2f2f2;
  }
  
  .delivery-areas-table tr:hover {
    background-color: #f5f5f5;
  }
  
  /* Стиль для выделенного района */
  .delivery-areas-table tr.selected {
    background-color: #e0e0e0;
  }
  