.sidebar {
    width: 250px; /* Установите нужную ширину */
    background-color: #f5f5f5; /* Выберите подходящий цвет фона */
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    overflow-y: auto;
    border-right: 1px solid #e0e0e0;
  }
  
  .sidebar__menu {
    padding: 20px;
  }
  
  .menu__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .list__item {
    padding: 10px 15px;
    font-size: 16px;
    color: #333; /* Выберите подходящий цвет текста */
    cursor: pointer;
  }
  
  .list__item--active {
    background-color: #e0e0e0; /* Выберите цвет для активного пункта меню */
  }
  .submenu {
    display: none;
    list-style-type: none;
    padding-left: 20px; /* или другой отступ, чтобы выделить вложенные элементы */
  }
  
  .submenu--visible {
    display: block;
  }
  
  .list__item--expanded > .submenu {
    display: block; /* или используйте класс .submenu--visible */
  }