/* OrganizationProfile.css */
.organization-profile {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
  }
  
  .form-row {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .form-row label {
    flex-basis: 100%;
    margin-bottom: 5px;
  }
  
  .form-row input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 1px;
  }
  
  .form-row input:last-child {
    margin-right: 0;
  }
  
  button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #357abd;
  }
  