/* ContractsList.css */
.contracts-list {
    padding: 20px;
  }
  
  .add-contract-btn {
    margin-bottom: 20px;
    padding: 10px;
    border: none;
    background-color: #4CAF50;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-contract-btn:hover {
    background-color: #45a049;
  }
  
  .contract {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 10px;
  }
  
  .contracts-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
  }
  
  .contracts-table th, .contracts-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .contracts-table th {
    background-color: #f2f2f2;
  }
  
  .total-remainder {
    font-weight: bold;
  }
  